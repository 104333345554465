import clsx from 'clsx';
import React from 'react';

export const descriptionClassName = clsx(
  'text-base leading-mega-loose',
  'transition empty:hidden ',
  'prose-a:text-brand-500 ',
  'prose-ol:ps-[1.5rem]',
  'prose-p:my-3 prose-p:pe-2 first:prose-p:mt-0 last:prose-p:mb-0',
  'prose-ul:my-6 first:prose-ul:mt-0 last:prose-ul:mb-0',
  'prose-li:pb-3 prose-li:text-xl prose-li:font-semibold prose-li:leading-extra-loose last:prose-li:pb-0',
);

type TDescriptionProps = {
  description?: React.JSX.Element | string | null;
  className?: string;
};

export const Description: React.FC<TDescriptionProps> = ({
  description,
  className,
}) => {
  if (!description) {
    return null;
  }

  return (
    <div className={clsx(descriptionClassName, className)}>{description}</div>
  );
};
