import React from 'react';

import { EIconBottomPadding, ESimpleIconSize, Icon } from '../../../atoms/Icon';
import { EDefaultCardV3TopIconSize } from '../constants';

import type { ImageProps } from 'next/image';

type TTopIconProps = {
  topIcon?: ImageProps | null;
  topIconSize?: EDefaultCardV3TopIconSize;
};

export const TopIcon: React.FC<TTopIconProps> = ({ topIcon, topIconSize }) => {
  if (!topIcon) {
    return null;
  }

  let size = ESimpleIconSize.Xl;
  if (topIconSize === EDefaultCardV3TopIconSize.Xl3) {
    size = ESimpleIconSize.XxxL;
  } else if (topIconSize === EDefaultCardV3TopIconSize.Xl2) {
    size = ESimpleIconSize.XxL;
  } else if (topIconSize === EDefaultCardV3TopIconSize.Xl1) {
    size = ESimpleIconSize.OneXl;
  }

  return (
    <Icon icon={topIcon} bottomPadding={EIconBottomPadding.No} size={size} />
  );
};
