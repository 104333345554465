import clsx from 'clsx';
import { cva } from 'cva';
import React from 'react';

import {
  EDefaultCardV3Size,
  EDefaultCardV3ImagePosition,
  EDefaultCardV3Paddings,
  EDefaultCardV3Theme,
  EDefaultCardV3TitleSize,
  EDefaultCardV3TopIconSize,
  THEMES,
} from './constants';
import { Description, Title, TopIcon } from './components';
import { ImageWithPlaceholder } from '../../atoms/ImageWithPlaceholder';

import type { ImageProps } from 'next/image';

const cardWrapperCVA = cva('min-w-[95%] basis-full px-2', {
  variants: {
    size: {
      [EDefaultCardV3Size.L]: 'tablet:min-w-[66.66%] tablet:basis-2/3',
      [EDefaultCardV3Size.M]: 'tablet:min-w-[50%] tablet:basis-1/2',
      [EDefaultCardV3Size.S]: 'tablet:min-w-[33.33%] tablet:basis-1/3',
      [EDefaultCardV3Size.Xs]:
        'tablet:min-w-[50%] tablet:basis-1/2 desktop:min-w-[25%] desktop:basis-1/4',
    },
  },
});

const cardContentCVA = cva('relative flex flex-1 flex-col gap-6 px-8', {
  variants: {
    paddings: {
      [EDefaultCardV3Paddings.TopAndBottom]: 'py-8',
      [EDefaultCardV3Paddings.OnlyBottom]: 'pb-8',
      [EDefaultCardV3Paddings.OnlyTop]: 'pt-8',
    },
  },
});

export type TDefaultCardV3CardProps = {
  image?: ImageProps | null;
  topIcon?: ImageProps | null;
  title?: React.JSX.Element | string;
  subTitle?: string;
  description?: React.JSX.Element | string;

  size?: EDefaultCardV3Size;
  imagePosition?: EDefaultCardV3ImagePosition;
  topIconSize?: EDefaultCardV3TopIconSize;
  titleSize?: EDefaultCardV3TitleSize;
  paddings?: EDefaultCardV3Paddings;
  theme?: EDefaultCardV3Theme;
  backgroundColor?: string;

  className?: string;
};

export const DefaultCardV3: React.FC<TDefaultCardV3CardProps> = props => {
  const {
    image,
    topIcon,
    title,
    subTitle,
    description,

    size = EDefaultCardV3Size.S,
    imagePosition = EDefaultCardV3ImagePosition.Bottom,
    topIconSize = EDefaultCardV3TopIconSize.Xl2,
    titleSize = EDefaultCardV3TitleSize.L,
    paddings = EDefaultCardV3Paddings.TopAndBottom,
    theme = EDefaultCardV3Theme.Light,
    backgroundColor,

    className,
  } = props;

  const themeStyles = THEMES[theme] || THEMES[EDefaultCardV3Theme.Light];

  return (
    <div className={cardWrapperCVA({ size, className })}>
      <div
        className={clsx(
          'relative flex h-full flex-1 justify-between overflow-hidden rounded-3xl',
          themeStyles.cardBackground,
          image && imagePosition === EDefaultCardV3ImagePosition.Top
            ? 'flex-col-reverse'
            : 'flex-col',
        )}
        style={{ backgroundColor }}
      >
        {(title || subTitle || topIcon || description) && (
          <div className={cardContentCVA({ paddings })}>
            <TopIcon topIcon={topIcon} topIconSize={topIconSize} />

            <Title
              title={title}
              titleSize={titleSize}
              className={clsx(themeStyles.titleColor)}
            />

            {subTitle ? (
              <div
                className={clsx(
                  'text-xl font-semibold leading-extra-loose',
                  themeStyles.subTitleColor,
                )}
              >
                {subTitle}
              </div>
            ) : null}

            <Description
              description={description}
              className={themeStyles.descriptionColor}
            />
          </div>
        )}

        {image && (
          <ImageWithPlaceholder
            {...image}
            className='h-auto w-full object-contain object-bottom'
          />
        )}
      </div>
    </div>
  );
};
