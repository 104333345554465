export enum EDefaultCardV3Size {
  L = 'L',
  M = 'M',
  S = 'S',
  Xs = 'XS',
}

export enum EDefaultCardV3ImagePosition {
  Top = 'Top',
  Bottom = 'Bottom',
}

export enum EDefaultCardV3TopIconSize {
  Xl3 = '3XL',
  Xl2 = '2XL',
  Xl1 = '1XL',
  Xl = 'XL',
}

export enum EDefaultCardV3TitleSize {
  Xl = 'XL',
  L = 'L',
  Ml = 'ML',
  M = 'M',
}

export enum EDefaultCardV3Paddings {
  TopAndBottom = 'TopAndBottom',
  OnlyTop = 'Top',
  OnlyBottom = 'Bottom',
}

export enum EDefaultCardV3Theme {
  Light = 'Light',
  Dark = 'Dark',
}

type TDefaultCardV3Theme = {
  cardBackground: string;
  titleColor: string;
  subTitleColor: string;
  descriptionColor: string;
};

export const THEMES: Record<EDefaultCardV3Theme, TDefaultCardV3Theme> = {
  [EDefaultCardV3Theme.Light]: {
    cardBackground: 'bg-interface-50',
    titleColor: 'text-interface-1000',
    subTitleColor: 'text-control-950',
    descriptionColor: 'text-interface-800 prose-li:text-control-950',
  },
  [EDefaultCardV3Theme.Dark]: {
    cardBackground: 'bg-interface-1000',
    titleColor: 'text-interface-50',
    subTitleColor: 'text-interface-50',
    descriptionColor: 'text-interface-500 prose-li:text-interface-50',
  },
};
