import {
  Container,
  DefaultCardsV3,
  DefaultCardV3,
  EContainerVariant,
  EDefaultCardsHorizontalScroll,
  EDefaultCardV3ImagePosition,
  EDefaultCardV3Paddings,
  EDefaultCardV3Theme,
  EDefaultCardV3TitleSize,
  EDefaultCardV3TopIconSize,
  toImageProps,
  toRichText,
  ERichTextBullets,
  toEnum,
} from '@front/shared/ds';
import React, { useMemo } from 'react';

import type { Section } from '@shared/master-types';
import { handleOpenModal } from '@front/master';
import { useAppDispatch } from '@front/master/store/store.hooks';

export type TDefaultCardsV3ConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'default-cards-v3' }
>;

type TCard = React.ComponentProps<typeof DefaultCardV3>;

export const DefaultCardsV3Connected: React.FC<
  TDefaultCardsV3ConnectedProps
> = props => {
  const { cards = [], horizontalScroll } = props;
  const appDispatch = useAppDispatch();
  const openModal = handleOpenModal(appDispatch);
  const banners = useMemo(
    () =>
      cards.map<TCard>(card => ({
        image: toImageProps(card.image),
        topIcon: toImageProps(card.topIcon),
        title: toRichText(card.title, { onOpenModal: openModal }),
        description: toRichText(card.description, {
          bulletsOptions: {
            bullets: ERichTextBullets.Custom,
          },
          classNames: {
            li: 'before:bg-brand-500 icon-checkmark-2 before:size-6 before:-start-[2.35rem] before:top-[0.2rem]',
            ul: 'ps-[2.5rem]',
          },
          onOpenModal: openModal,
        }),
        subTitle: card.subTitle,
        topIconSize: toEnum(EDefaultCardV3TopIconSize, card.topIconSize),
        titleSize: toEnum(EDefaultCardV3TitleSize, card.titleSize),
        imagePosition: toEnum(EDefaultCardV3ImagePosition, card.imagePosition),
        paddings: toEnum(EDefaultCardV3Paddings, card.paddings),
        theme: toEnum(EDefaultCardV3Theme, card.theme),
        backgroundColor: card.backgroundColor,
      })),
    [cards],
  );

  return (
    <Container
      variant={EContainerVariant.Full}
      className='max-w-[462px] tablet:max-w-full'
    >
      <DefaultCardsV3
        banners={banners}
        horizontalScroll={horizontalScroll as EDefaultCardsHorizontalScroll}
      />
    </Container>
  );
};

export default DefaultCardsV3Connected;
