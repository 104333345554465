import { cva } from 'cva';
import React from 'react';

import { useDragScrolling } from '../../../hooks';
import { DefaultCardV3 } from './DefaultCardV3';
import { EDefaultCardsHorizontalScroll } from '../DefaultCards';

const wrapperCva = cva('scrollbar-hidden flex', {
  variants: {
    horizontalScroll: {
      [EDefaultCardsHorizontalScroll.Disabled]: '',
      [EDefaultCardsHorizontalScroll.Enabled]: 'overflow-auto',
      [EDefaultCardsHorizontalScroll.Mobile]:
        'overflow-auto xl:overflow-hidden',
      [EDefaultCardsHorizontalScroll.Desktop]: 'xl:overflow-auto',
    },
  },
});

const cardCva = cva('-mx-2 flex items-stretch gap-y-4', {
  variants: {
    horizontalScroll: {
      [EDefaultCardsHorizontalScroll.Disabled]: 'flex-wrap',
      [EDefaultCardsHorizontalScroll.Enabled]: 'flex-nowrap',
      [EDefaultCardsHorizontalScroll.Mobile]: 'flex-nowrap xl:flex-wrap',
      [EDefaultCardsHorizontalScroll.Desktop]: 'flex-wrap xl:flex-nowrap',
    },
  },
});

type TDefaultCardsV2Props = {
  banners: React.ComponentProps<typeof DefaultCardV3>[];
  horizontalScroll?: EDefaultCardsHorizontalScroll;
};

export const DefaultCardsV3: React.FC<TDefaultCardsV2Props> = props => {
  const { banners, horizontalScroll } = props;

  const { scrollableRef, bind } = useDragScrolling({
    enabled: horizontalScroll !== EDefaultCardsHorizontalScroll.Disabled,
  });

  return (
    <section
      ref={scrollableRef}
      className={wrapperCva({ horizontalScroll })}
      {...bind()}
    >
      <div className='w-full'>
        <div className={cardCva({ horizontalScroll })}>
          {banners.map((banner, i) => (
            <DefaultCardV3 key={i} {...banner} />
          ))}
        </div>
      </div>
    </section>
  );
};
