import clsx from 'clsx';
import React from 'react';

import { ETitlePartsSize, TitlePart } from '../../../atoms/TitlePart';
import { EDefaultCardV3TitleSize } from '../constants';
import { toEnum } from '../../../../utils';

type TTitleProps = {
  title?: React.JSX.Element | string;
  titleSize?: EDefaultCardV3TitleSize;
  className?: string;
};

export const Title: React.FC<TTitleProps> = ({
  title,
  titleSize,
  className,
}) => {
  if (!title) {
    return null;
  }

  return (
    <TitlePart
      tag='h3'
      size={toEnum(ETitlePartsSize, titleSize) || ETitlePartsSize.L}
      className={clsx('flex flex-row justify-between gap-4', className)}
    >
      <div className='[overflow-wrap:anywhere]'>{title}</div>
    </TitlePart>
  );
};
